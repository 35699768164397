/* eslint-disable max-lines */

export const WALL_YOGA_YOUNG_DEFAULT_FLOW = {
    branch_name: 'general',
    flow_name: 'yoga_go__general__women_yoga_wlp_v2__Control',
    start_page: {
        id: 'start_age_full2',
        name: 'start_age_full2_wallpilates_real_18',
        config: {
            ScreenTitle: 'wallpilates',
            Screen1Subtitle: 'default',
            Screen2Subtitle: 'default',
            femaleAgeRange1: '18-25',
            femaleAgeRange2: '26-35',
            femaleAgeRange3: '36-45',
            femaleAgeRange4: '46+',
            maleAgeRange1: '18-25',
            maleAgeRange2: '26-35',
            maleAgeRange3: '26-45',
            maleAgeRange4: '46+',
            maleModelImage1: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/start-m_18-25.png',
            maleModelImage2: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/start-m_26-35.png',
            maleModelImage3: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/start-m_36-45.png',
            maleModelImage4: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/start-m_46.png',
            femaleModelImage1: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/18-25.png',
            femaleModelImage2: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/26-35.png',
            femaleModelImage3: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/36-45.png',
            femaleModelImage4: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/46.png',
        },
    },
    onboarding_flow: [
        {
            id: 'ob_social_proof_full',
            name: 'ob_social_proof_full_pilates_real',
            config: {
                ScreenTitle: 'default',
                ScreenSubtitle: 'default',
                maleModelImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/pilates/social-proof-real-male.png',
                femaleModelImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/pilates/img-social-proof.png',
            },
        },
        { id: 'ob_goals_b', name: 'ob_goals_b', config: {} },
        { id: 'ob_tags_a', name: 'ob_tags_a', config: {} },
        {
            id: 'ob_familiarity_full',
            name: 'ob_familiarity_full_wallpilates',
            config: { ScreenTitle: 'wallpilates' },
        },
        { id: 'ob_body_type', name: 'ob_body_type', config: {} },
        { id: 'ob_body_type_target', name: 'ob_body_type_target', config: {} },
        {
            id: 'ob_target_zones_full',
            name: 'ob_target_zones_full_old',
            config: {
                femaleModelImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/pilates/female_target_zones.png',
                maleModelImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/zones-male.png',
            },
        },
        { id: 'ob_body_image', name: 'ob_body_image', config: {} },
        { id: 'ob_fortunately', name: 'ob_fortunately', config: {} },
        { id: 'ob_activity_level', name: 'ob_activity_level', config: {} },
        {
            id: 'ob_general1',
            name: 'ob_general1_obstacles',
            config: {
                screenTitle: 'What’s typically keeping you from exercising regularly?',
                screenSubtitle: "Let's customize your plan to eliminate those factors and enjoy the routine!",
                option1: 'Lack of time',
                option2: 'Lack of motivation',
                option3: "Workouts aren't interesting",
                option4: 'Workouts are too hard',
                option5: 'Health issues',
            },
        },
        {
            id: 'ob_general2',
            name: 'ob_general2_cycle',
            config: {
                screenTitle: 'Does your cycle usually affect your activity level?',
                screenSubtitle:
                    "Some fitness plans made for men don’t work for women. Let's take into account the nature of your body!",
                option1: 'Yes, it does!',
                option2: 'No, not really',
                option3: 'It depends',
                option4: "I'm pregnant",
                option5: "I'm menopausal",
            },
        },
        { id: 'ob_walking', name: 'ob_walking', config: {} },
        { id: 'ob_pushups_squats', name: 'ob_pushups_squats', config: {} },
        { id: 'ob_pilates_level', name: 'ob_pilates_level', config: {} },
        { id: 'ob_bending_knees', name: 'ob_bending_knees', config: {} },
        {
            id: 'ob_general3',
            name: 'ob_general3_emotional_state_upd',
            config: {
                screenTitle: 'How would you describe your current emotional state?',
                screenSubtitle: 'off',
                option1: 'Stressed and anxious',
                option2: 'Mood swings',
                option3: 'Emotionally balanced',
                option4: 'Burned out',
                option5: 'Other',
            },
        },
        { id: 'ob_meals_feel', name: 'ob_meals_feel', config: {} },
        { id: 'ob_sleep', name: 'ob_sleep', config: {} },
        { id: 'ob_water', name: 'ob_water', config: {} },
        { id: 'ob_diets', name: 'ob_diets', config: {} },
        { id: 'ob_height', name: 'ob_height', config: {} },
        { id: 'ob_weight', name: 'ob_weight', config: {} },
        { id: 'ob_weight_target', name: 'ob_weight_target', config: {} },
        { id: 'ob_age', name: 'ob_age', config: {} },
        { id: 'feedback_summary_fitlevel', name: 'feedback_summary_fitlevel', config: {} },
        { id: 'ob_occasion', name: 'ob_occasion', config: {} },
        { id: 'ob_occasion_result', name: 'ob_occasion_result', config: {} },
        {
            id: 'ob_general',
            name: 'ob_general_motivation',
            config: {
                screenTitle: 'default',
                screenSubtitle: 'default',
                option1: 'default',
                option2: 'default',
                option3: 'default',
                option4: 'default',
                option5: 'default',
            },
        },
        { id: 'ob_selftime', name: 'ob_selftime', config: {} },
        {
            id: 'ob_general4',
            name: 'ob_general4_additional',
            config: {
                screenTitle: 'Choose some additional workouts',
                screenSubtitle: 'We have 640+ guided workouts with video tutorials and 50+ customized series!',
                option1: 'Yoga',
                option2: 'Strength',
                option3: 'Cardio',
                option4: 'Relaxation',
                option5: "I'll decide later",
            },
        },
        { id: 'creating', name: 'creating', config: {} },
        { id: 'email_account_a', name: 'email_account_a', config: {} },
        {
            id: 'email_a_full',
            name: 'email_a_full_wallpilates',
            config: { ScreenTitle: 'wallpilates' },
        },
        {
            id: 'email_consent_full',
            name: 'email_consent_full_wallpilates',
            config: { ScreenTitle: 'wallpilates' },
        },
        {
            id: 'result_full',
            name: 'result_full_wallpilates',
            config: { ScreenTitle: 'wallpilates' },
        },
    ],
    payment_flow: [
        {
            id: 'payment_g7_golf14',
            name: 'payment_g7_golf14_wallpilates_latam_newprices_ppoff_v1',
            parent_id: null,
            is_paid: null,
            skip_checkout: true,
            products: [
                {
                    payment_type: 'subscription',
                    id: '7800aa10-c1ff-4f12-93c2-9e731d71bfdd',
                    name: 'One week intro + One month + Payment geo_exp_lower',
                    trial: 7,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 122,
                    price_per_state: 79,
                    start_price: 853,
                    price: 554,
                    sign: '$',
                    code: 'MXN',
                    ltv: 4069,
                },
                {
                    payment_type: 'subscription',
                    id: '02f5680a-52f2-44d7-bf17-7eecfd0550b5',
                    name: 'One month intro + One month + Payment geo_exp_lower',
                    trial: 31,
                    period: 31,
                    product_code: 'main',
                    is_preselected: true,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 113,
                    price_per_state: 34,
                    start_price: 3494,
                    price: 1048,
                    sign: '$',
                    code: 'USD',
                    ltv: 4293,
                },
                {
                    payment_type: 'subscription',
                    id: '272a8346-8fc9-427d-99c4-7a9e4499b5fc',
                    name: 'Three month intro + Three month + Payment geo_exp_lower',
                    trial: 90,
                    period: 90,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 64,
                    price_per_state: 19,
                    start_price: 5804,
                    price: 1741,
                    sign: '$',
                    code: 'MXN',
                    ltv: 4360,
                },
                {
                    payment_type: 'subscription',
                    id: '33b1931a-c6d7-4e93-a596-1568949d756f',
                    name: 'One week intro + One month + Timer geo_exp_lower',
                    trial: 7,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 122,
                    start_price: 853,
                    price: 853,
                    sign: 'R',
                    code: 'ZAR',
                    ltv: 4279,
                },
                {
                    payment_type: 'subscription',
                    id: 'c9e43924-5dbd-4428-977d-9367e001c1df',
                    name: 'One month intro + One month + Timer geo_exp_lower',
                    trial: 31,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 113,
                    start_price: 3494,
                    price: 3494,
                    sign: '$',
                    code: 'MXN',
                    ltv: 5765,
                },
                {
                    payment_type: 'subscription',
                    id: '3568bb45-80b7-4fd3-852b-934fde10a901',
                    name: 'Three month intro + Three month + Timer geo_exp_lower',
                    trial: 90,
                    period: 90,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 64,
                    start_price: 5804,
                    price: 5804,
                    sign: '$',
                    code: 'MXN',
                    ltv: 7213,
                },
            ],
            config: {
                checkout: {
                    checkoutPayPal: 'off',
                    checkoutGooglePay: 'on',
                    checkoutFullScreen: 'off',
                    ratings: 'off',
                    secondTitle: 'off',
                    checkoutDisclaimer: 'off',
                    discount: 'off',
                    mainTitle: 'default',
                    subtitle: 'default',
                    label: 'off',
                    maleImage: 'off',
                    femaleImage: 'off',
                },
                femaleModelImageBefore:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/before-f.png',
                FullPrices: 'on',
                StatBadgeLevel: 'pilates',
                ScreenTitle: 'wallpilates',
                femaleModelImageAfter:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/after-f.png',
                maleModelImageBefore:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/fat.png',
                maleModelImageAfter:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/fit.png',
            },
        },
    ],
    expire: 1720863575787,
};
