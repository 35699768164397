import classNames from 'classnames/bind';

import {
    SOMATIC_YOGA_GO,
    THE_CHAIR_YOGA_MAN,
    THE_CHAIR_YOGA_WOMAN,
    WALL_YOGA_YOUNG,
    YOGA_WALL_PILATES,
} from 'constants/mirrors/mirrorsHosts';

import type { LogoTypes } from 'components/Logo/types';
import { ReactComponent as YogaWallPilatesSmall } from 'components/Logo/img/yogawallpilatesSmall.svg';
import { ReactComponent as YogaWallPilates } from 'components/Logo/img/yogawallpilates.svg';
import { ReactComponent as WallYogaYoungSmall } from 'components/Logo/img/wallyogayoungSmall.svg';
import { ReactComponent as WallYogaYoung } from 'components/Logo/img/wallyogayoung.svg';
import { ReactComponent as TheChairYogaWomanSmall } from 'components/Logo/img/thechairyogawomanSmall.svg';
import { ReactComponent as TheChairYogaWoman } from 'components/Logo/img/thechairyogawoman.svg';
import { ReactComponent as TheChairYogaManSmall } from 'components/Logo/img/thechairyogamanSmall.svg';
import { ReactComponent as TheChairYogaMan } from 'components/Logo/img/thechairyogaman.svg';
import { ReactComponent as SomaticYogaGoSmall } from 'components/Logo/img/somaticyogagoSmall.svg';
import { ReactComponent as SomaticYogaGo } from 'components/Logo/img/somaticyogago.svg';

import styles from './Logo.module.css';

const cx = classNames.bind(styles);

export default function Logo({ centered, white, isOnboarding, onboardingLogo, logoFooter, className }: LogoTypes) {
    const logoClass = cx('logo', { centered, white, isOnboarding });
    const logoDefaultImgClass = cx('logoImgDefault', className, {
        logoImgOnboarding: isOnboarding && onboardingLogo,
        logoFooter: logoFooter,
    });

    let LogoSmall, LogoDefault;

    switch (window.location.host) {
        case THE_CHAIR_YOGA_MAN:
            LogoSmall = TheChairYogaManSmall;
            LogoDefault = TheChairYogaMan;
            break;

        case THE_CHAIR_YOGA_WOMAN:
            LogoSmall = TheChairYogaWomanSmall;
            LogoDefault = TheChairYogaWoman;
            break;

        case YOGA_WALL_PILATES:
            LogoSmall = YogaWallPilatesSmall;
            LogoDefault = YogaWallPilates;
            break;

        case WALL_YOGA_YOUNG:
            LogoSmall = WallYogaYoungSmall;
            LogoDefault = WallYogaYoung;
            break;

        case SOMATIC_YOGA_GO:
            LogoSmall = SomaticYogaGoSmall;
            LogoDefault = SomaticYogaGo;
            break;

        default:
            LogoSmall = TheChairYogaManSmall;
            LogoDefault = TheChairYogaMan;
    }

    return (
        <div className={logoClass}>
            {(isOnboarding && onboardingLogo) || logoFooter ? (
                <LogoSmall className={className} />
            ) : (
                <LogoDefault className={logoDefaultImgClass} />
            )}
        </div>
    );
}
