const HOST = window.location.host;

export const LOCALHOST = 'localhost:8080';
export const STAGE_HOST = 'yoga-mirrors-front-dev.asqq.io';

const DEV_HOSTS = [LOCALHOST, STAGE_HOST];

export const THE_CHAIR_YOGA_MAN = DEV_HOSTS.includes(HOST) ? HOST : 'thechairyogaman.com';
export const THE_CHAIR_YOGA_WOMAN = 'thechairyogawoman.com';
export const YOGA_WALL_PILATES = 'yogawallpilates.com';
export const WALL_YOGA_YOUNG = 'wallyogayoung.com';
export const SOMATIC_YOGA_GO = 'somaticyogago.com';
