/* eslint-disable max-lines */

export const YOGA_WALL_PILATES_DEFAULT_FLOW = {
    branch_name: 'general',
    flow_name: 'yoga_go__general__women_wlp_v2__Control',
    start_page: {
        id: 'start_gender',
        name: 'start_gender',
        config: {},
    },
    onboarding_flow: [
        { id: 'ob_social_proof', name: 'ob_social_proof', config: {} },
        {
            id: 'ob_feedback',
            name: 'ob_feedback_welcoming',
            config: {
                screenTitle: 'wall',
                screenText: 'wall',
                femaleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_female_fat.png',
                maleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_male2.png',
                femaleVideo: 'no',
                maleVideo: 'no',
                screenColor: '#F3F3F8',
            },
        },
        { id: 'ob_goals', name: 'ob_goals', config: {} },
        {
            id: 'ob_feedback_a',
            name: 'ob_feedback_a_goals',
            config: {
                screenTitle: 'first_step',
                screenText: 'adjust',
                femaleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_female_blond.png',
                maleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_male2.png',
                femaleVideo: 'no',
                maleVideo: 'no',
                screenColor: '#F3F3F8',
            },
        },
        {
            id: 'ob_familiarity_full',
            name: 'ob_familiarity_full_wallpilates',
            config: { ScreenTitle: 'wallpilates' },
        },
        {
            id: 'ob_feedback_b',
            name: 'ob_feedback_b_familiarity',
            config: {
                screenTitle: 'wall_workouts',
                screenText: 'wall_workouts',
                femaleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_female_squad.png',
                maleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_male2.png',
                femaleVideo: 'no',
                maleVideo: 'no',
                screenColor: '#F3F3F8',
            },
        },
        {
            id: 'ob_current_body_type_a',
            name: 'ob_current_body_type_a_v',
            config: {
                femaleOptionImage1:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_CF_regular-violet.png',
                femaleOptionImage2:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_CF_flabby-violet.png',
                femaleOptionImage3:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_CF_extra-violet.png',
                femaleOptionImage4:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_CF_over-violet.png',
                femaleOptionImage5:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/5_CF_obese-violet.png',
                maleOptionImage1:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_CM_Skinny.png',
                maleOptionImage2:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_CM_flabby.png',
                maleOptionImage3:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_CM_Extra.png',
                maleOptionImage4: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_CM_over.png',
                maleOptionImage5:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/5_CM_obese.png',
                ScreenTitle: 'bodytype',
            },
        },
        {
            id: 'ob_target_body_type_a',
            name: 'ob_target_body_type_a_v',
            config: {
                femaleOptionImage1:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_TF_regular-violet.png',
                femaleOptionImage2:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_TF_fit-violet.png',
                femaleOptionImage3:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_TF_flat-violet.png',
                femaleOptionImage4:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_TF_athletic-violet.png',
                femaleOptionImage5:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/5_TF_curvy-violet.png',
                maleOptionImage1: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_TM_Cut.png',
                maleOptionImage2:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_TM_Shredded.png',
                maleOptionImage3: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_TM_bulk.png',
                maleOptionImage4:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_TM_Extra_Bulk.png',
                maleOptionImage5:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/5_TM_Muscle.png',
                ScreenTitle: 'targetbodytype',
            },
        },
        {
            id: 'ob_target_zones_c',
            name: 'ob_target_zones_c_prob',
            config: { ScreenTitle: 'problemareas' },
        },
        { id: 'ob_body_image', name: 'ob_body_image', config: {} },
        {
            id: 'ob_feedback_c',
            name: 'ob_feedback_c_motivation',
            config: {
                screenTitle: 'amazing',
                screenText: 'fear',
                femaleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_weight_loss.png',
                maleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_male2.png',
                femaleVideo: 'no',
                maleVideo: 'no',
                screenColor: '#F3F3F8',
            },
        },
        { id: 'ob_activity_level', name: 'ob_activity_level', config: {} },
        { id: 'ob_walking', name: 'ob_walking', config: {} },
        { id: 'ob_pushups_squats', name: 'ob_pushups_squats', config: {} },
        { id: 'ob_pilates_level', name: 'ob_pilates_level', config: {} },
        { id: 'ob_bending_knees', name: 'ob_bending_knees', config: {} },
        {
            id: 'ob_feedback_d',
            name: 'ob_feedback_d_level',
            config: {
                screenTitle: 'adjust',
                screenText: 'adapt',
                femaleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_female7.png',
                maleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_male2.png',
                femaleVideo: 'no',
                maleVideo: 'no',
                screenColor: '#F3F3F8',
            },
        },
        { id: 'ob_tags_a', name: 'ob_tags_a', config: {} },
        { id: 'ob_meals_feel', name: 'ob_meals_feel', config: {} },
        { id: 'ob_sleep', name: 'ob_sleep', config: {} },
        { id: 'ob_water', name: 'ob_water', config: {} },
        { id: 'ob_diets', name: 'ob_diets', config: {} },
        { id: 'ob_height', name: 'ob_height', config: {} },
        { id: 'ob_weight', name: 'ob_weight', config: {} },
        { id: 'ob_weight_target', name: 'ob_weight_target', config: {} },
        { id: 'ob_age', name: 'ob_age', config: {} },
        {
            id: 'ob_feedback_e',
            name: 'ob_feedback_e_thanks',
            config: {
                screenTitle: 'thank_you',
                screenText: 'thank_you',
                femaleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_grey_female2.png',
                maleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_male2.png',
                femaleVideo: 'no',
                maleVideo: 'no',
                screenColor: '#F3F3F8',
            },
        },
        {
            id: 'feedback_summary_fitlevel_full',
            name: 'feedback_summary_fitlevel_full_default',
            config: { screenTitle: 'start_level' },
        },
        { id: 'ob_occasion', name: 'ob_occasion', config: {} },
        { id: 'ob_occasion_result', name: 'ob_occasion_result', config: {} },
        { id: 'creating', name: 'creating', config: {} },
        { id: 'email_account_a', name: 'email_account_a', config: {} },
        {
            id: 'email_a_full',
            name: 'email_a_full_wallpilates',
            config: { ScreenTitle: 'wallpilates' },
        },
        {
            id: 'email_consent_full',
            name: 'email_consent_full_wallpilates',
            config: { ScreenTitle: 'wallpilates' },
        },
        {
            id: 'result_full',
            name: 'result_full_wallpilates',
            config: { ScreenTitle: 'wallpilates' },
        },
    ],
    payment_flow: [
        {
            id: 'payment_g12_golf14',
            name: 'payment_g12_golf14_nodisclaimer_wlp_35',
            parent_id: null,
            is_paid: null,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '7a83d865-4be2-41e1-92d6-072145e50d9e',
                    name: 'One-week intro (6.93) + One-month (38.95)',
                    trial: 7,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 254,
                    price_per_state: 99,
                    start_price: 1777,
                    price: 693,
                    sign: '$',
                    code: 'USD',
                    ltv: 4042,
                },
                {
                    payment_type: 'subscription',
                    id: 'd8939b06-6648-46c7-86fe-0c5df60ed569',
                    name: 'One-month intro (15.19) + One-month (38.95)',
                    trial: 31,
                    period: 31,
                    product_code: 'main',
                    is_preselected: true,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 126,
                    price_per_state: 49,
                    start_price: 3895.0000000000005,
                    price: 1519,
                    sign: '$',
                    code: 'USD',
                    ltv: 5169,
                },
                {
                    payment_type: 'subscription',
                    id: '6e0bbd02-ee0b-4c93-a86f-7181f6ec2f51',
                    name: 'Three-month intro (25.99) + Three-month (66.65)',
                    trial: 90,
                    period: 90,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 74,
                    price_per_state: 29,
                    start_price: 6665.000000000001,
                    price: 2599,
                    sign: '$',
                    code: 'USD',
                    ltv: 6505,
                },
                {
                    payment_type: 'subscription',
                    id: '743ec4d9-802f-4326-9907-d00a5d43ea1b',
                    name: 'One-week intro (17.77) + One-month (38.95)',
                    trial: 7,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 254,
                    start_price: 1777,
                    price: 1777,
                    sign: '$',
                    code: 'USD',
                    ltv: 4625,
                },
                {
                    payment_type: 'subscription',
                    id: '23e69cc7-d368-47b0-ad0b-42bf3c65735e',
                    name: 'One-month intro (38.95) + One-month (38.95)',
                    trial: 31,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 126,
                    start_price: 3895.0000000000005,
                    price: 3895,
                    sign: '$',
                    code: 'USD',
                    ltv: 6632,
                },
                {
                    payment_type: 'subscription',
                    id: 'a51e5fc3-aa3b-43eb-91e3-3b9c559d4666',
                    name: 'Three-month intro (66.65) + Three-month (66.65)',
                    trial: 90,
                    period: 90,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 74,
                    start_price: 6665.000000000001,
                    price: 6665,
                    sign: '$',
                    code: 'USD',
                    ltv: 9570,
                },
            ],
            config: {
                checkout: {
                    checkoutPayPal: 'on',
                    checkoutGooglePay: 'off',
                    checkoutDisclaimer: 'off',
                    checkoutFullScreen: 'off',
                    ratings: 'off',
                    discount: 'off',
                    femaleImage: 'off',
                    maleImage: 'off',
                    secondTitle: 'off',
                    mainTitle: 'default',
                    subtitle: 'default',
                    label: 'off',
                },
                FullPrices: 'on',
                StatBadgeLevel: 'pilates',
                ScreenTitle: 'wallpilates',
                femaleModelImageBefore:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/before-f.png',
                femaleModelImageAfter:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/after-f.png',
                maleModelImageBefore:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/fat.png ',
                maleModelImageAfter:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/fit.png',
            },
        },
    ],
    expire: 1720862985984,
};
