/* eslint-disable max-lines */

export const THE_CHAIR_YOGA_MAN_DEFAULT_FLOW = {
    branch_name: 'general',
    flow_name: 'yoga_go__general__men_chair__Control',
    start_page: {
        id: 'start_age_full2',
        name: 'start_age_full2_chair_3dmale',
        config: {
            ScreenTitle: 'chair',
            Screen1Subtitle: 'default',
            Screen2Subtitle: 'default',
            femaleAgeRange1: '35-44',
            femaleAgeRange2: '45-54',
            femaleAgeRange3: '55-64',
            femaleAgeRange4: '65+',
            maleAgeRange1: '35-44',
            maleAgeRange2: '45-54',
            maleAgeRange3: '55-64',
            maleAgeRange4: '65+',
            maleModelImage1:
                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3D%20male%20models/35-44.png',
            maleModelImage2:
                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3D%20male%20models/45-54.png',
            maleModelImage3:
                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3D%20male%20models/55-64.png',
            maleModelImage4:
                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3D%20male%20models/65.png',
            femaleModelImage1: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_female_35.png',
            femaleModelImage2: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_female_45.png',
            femaleModelImage3: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_female_55.png',
            femaleModelImage4: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_female_65.png',
        },
    },
    onboarding_flow: [
        {
            id: 'ob_social_proof_full',
            name: 'ob_social_proof_full_3dmale',
            config: {
                ScreenTitle: 'default',
                ScreenSubtitle: 'default',
                maleModelImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3D%20male%20models/social-proof-3D-male.png',
                femaleModelImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/img-social-proof_female.png',
            },
        },
        { id: 'ob_goals', name: 'ob_goals', config: {} },
        {
            id: 'ob_familiarity_full',
            name: 'ob_familiarity_full_chair',
            config: { ScreenTitle: 'chair' },
        },
        { id: 'ob_body_type', name: 'ob_body_type', config: {} },
        { id: 'ob_body_type_target', name: 'ob_body_type_target', config: {} },
        {
            id: 'ob_target_zones_full',
            name: 'ob_target_zones_full_3dmale',
            config: {
                maleModelImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3D%20male%20models/target-zones-3D-male.png',
                femaleModelImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/zones-female2.png',
            },
        },
        { id: 'ob_body_image', name: 'ob_body_image', config: {} },
        { id: 'ob_activity_level', name: 'ob_activity_level', config: {} },
        { id: 'ob_walking_feedback', name: 'ob_walking_feedback', config: {} },
        { id: 'ob_pushups_squats_feedback', name: 'ob_pushups_squats_feedback', config: {} },
        { id: 'ob_yoga_level', name: 'ob_yoga_level', config: {} },
        { id: 'ob_bending_knees_feedback', name: 'ob_bending_knees_feedback', config: {} },
        { id: 'ob_tags_a', name: 'ob_tags_a', config: {} },
        { id: 'ob_restrictive_diet', name: 'ob_restrictive_diet', config: {} },
        { id: 'ob_height', name: 'ob_height', config: {} },
        { id: 'ob_weight', name: 'ob_weight', config: {} },
        { id: 'ob_weight_target', name: 'ob_weight_target', config: {} },
        { id: 'ob_age', name: 'ob_age', config: {} },
        {
            id: 'feedback_summary_fitlevel_full',
            name: 'feedback_summary_fitlevel_full_3d_chair_default',
            config: {
                femaleImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/summary_fenale.png',
                maleImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/summary_male.png',
                screenTitle: 'default',
            },
        },
        { id: 'ob_occasion', name: 'ob_occasion', config: {} },
        { id: 'ob_occasion_result', name: 'ob_occasion_result', config: {} },
        { id: 'creating', name: 'creating', config: {} },
        {
            id: 'email_a_full',
            name: 'email_a_full_chair',
            config: { ScreenTitle: 'chair' },
        },
        {
            id: 'email_consent_full',
            name: 'email_consent_full_chair',
            config: { ScreenTitle: 'chair' },
        },
        {
            id: 'result_full',
            name: 'result_full_chair',
            config: { ScreenTitle: 'chair' },
        },
    ],
    payment_flow: [
        {
            id: 'payment_g12_golf14',
            name: 'payment_g12_golf14_nodisclaimer_chair2',
            parent_id: null,
            is_paid: null,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '7a83d865-4be2-41e1-92d6-072145e50d9e',
                    name: 'One-week intro (6.93) + One-month (38.95)',
                    trial: 7,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 254,
                    price_per_state: 99,
                    start_price: 1777,
                    price: 693,
                    sign: '$',
                    code: 'USD',
                    ltv: 4042,
                },
                {
                    payment_type: 'subscription',
                    id: 'd8939b06-6648-46c7-86fe-0c5df60ed569',
                    name: 'One-month intro (15.19) + One-month (38.95)',
                    trial: 31,
                    period: 31,
                    product_code: 'main',
                    is_preselected: true,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 126,
                    price_per_state: 49,
                    start_price: 3895.0000000000005,
                    price: 1519,
                    sign: '$',
                    code: 'USD',
                    ltv: 5169,
                },
                {
                    payment_type: 'subscription',
                    id: '6e0bbd02-ee0b-4c93-a86f-7181f6ec2f51',
                    name: 'Three-month intro (25.99) + Three-month (66.65)',
                    trial: 90,
                    period: 90,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 74,
                    price_per_state: 29,
                    start_price: 6665.000000000001,
                    price: 2599,
                    sign: '$',
                    code: 'USD',
                    ltv: 6505,
                },
                {
                    payment_type: 'subscription',
                    id: '743ec4d9-802f-4326-9907-d00a5d43ea1b',
                    name: 'One-week intro (17.77) + One-month (38.95)',
                    trial: 7,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 254,
                    start_price: 1777,
                    price: 1777,
                    sign: '$',
                    code: 'USD',
                    ltv: 4625,
                },
                {
                    payment_type: 'subscription',
                    id: '23e69cc7-d368-47b0-ad0b-42bf3c65735e',
                    name: 'One-month intro (38.95) + One-month (38.95)',
                    trial: 31,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 126,
                    start_price: 3895.0000000000005,
                    price: 3895,
                    sign: '$',
                    code: 'USD',
                    ltv: 6632,
                },
                {
                    payment_type: 'subscription',
                    id: 'a51e5fc3-aa3b-43eb-91e3-3b9c559d4666',
                    name: 'Three-month intro (66.65) + Three-month (66.65)',
                    trial: 90,
                    period: 90,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 74,
                    start_price: 6665.000000000001,
                    price: 6665,
                    sign: '$',
                    code: 'USD',
                    ltv: 9570,
                },
            ],
            config: {
                checkout: {
                    checkoutPayPal: 'on',
                    checkoutGooglePay: 'off',
                    checkoutDisclaimer: 'off',
                    checkoutFullScreen: 'off',
                    ratings: 'off',
                    discount: 'off',
                    femaleImage: 'off',
                    maleImage: 'off',
                    secondTitle: 'on',
                    mainTitle: 'default',
                    subtitle: 'default',
                    label: 'off',
                },
                FullPrices: 'on',
                StatBadgeLevel: 'default',
                ScreenTitle: 'chair',
                femaleModelImageBefore:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/female_fefore_payment.png',
                femaleModelImageAfter:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/female_after_payment.png',
                maleModelImageBefore:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/male_before_payment_3d.png',
                maleModelImageAfter:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/male_after_payment_3d.png',
            },
        },
    ],
    expire: 1720848673649,
};
