// constants
import { FlowStructure } from 'testania';

import {
    YOGA_WALL_PILATES,
    WALL_YOGA_YOUNG,
    THE_CHAIR_YOGA_MAN,
    THE_CHAIR_YOGA_WOMAN,
    SOMATIC_YOGA_GO,
} from 'constants/mirrors/mirrorsHosts';
import { YOGA_WALL_PILATES_DEFAULT_FLOW } from 'constants/mirrors/mirrorsDefaultFlows/yogawallpilates';
import { WALL_YOGA_YOUNG_DEFAULT_FLOW } from 'constants/mirrors/mirrorsDefaultFlows/wallyogayoung';
import { THE_CHAIR_YOGA_WOMAN_DEFAULT_FLOW } from 'constants/mirrors/mirrorsDefaultFlows/thechairyogawomanFlow';
import { THE_CHAIR_YOGA_MAN_DEFAULT_FLOW } from 'constants/mirrors/mirrorsDefaultFlows/thechairyogamanFlow';
import { SOMATIC_YOGA_GO_DEFAULT_FLOW } from 'constants/mirrors/mirrorsDefaultFlows/somaticyogago';

interface I_FLOW_CONFIG_BY_DOMAIN {
    [domain: string]: { defaultFlow: FlowStructure };
}

const FLOW_CONFIG_BY_DOMAIN: I_FLOW_CONFIG_BY_DOMAIN = {
    [YOGA_WALL_PILATES]: {
        defaultFlow: YOGA_WALL_PILATES_DEFAULT_FLOW,
    },
    [WALL_YOGA_YOUNG]: {
        defaultFlow: WALL_YOGA_YOUNG_DEFAULT_FLOW,
    },
    [THE_CHAIR_YOGA_MAN]: {
        defaultFlow: THE_CHAIR_YOGA_MAN_DEFAULT_FLOW,
    },
    [THE_CHAIR_YOGA_WOMAN]: {
        defaultFlow: THE_CHAIR_YOGA_WOMAN_DEFAULT_FLOW,
    },
    [SOMATIC_YOGA_GO]: {
        defaultFlow: SOMATIC_YOGA_GO_DEFAULT_FLOW,
    },
};

export const getMirrorConfigBy = () => {
    const host = window.location.host;

    return FLOW_CONFIG_BY_DOMAIN[host];
};
