import { Theme } from 'wikr-core-components';
import { useState } from 'react';

import { getFaviconElement } from 'helpers/utils';

interface ThemeConfigScheme {
    inlineStyle: object;
    themeStyle: object;
    faviconUrl: string;
    logoUrl: string;
}

const dynamicCSSVariables = [
    'primary-default',
    'primary-hovered',
    'primary-active',
    'primary-disabled',
    'decorative-d',
    'safe',
];

const root = document.documentElement;

const useThemeConfiguration = (themeConfig: Record<string, ThemeConfigScheme>): Theme => {
    const [theme, setTheme] = useState<Theme>();

    if (!theme) {
        const hostName = window.location.host;

        const { themeStyle, faviconUrl } = themeConfig?.[hostName] || themeConfig['default'];

        dynamicCSSVariables.map((variable) => {
            // @ts-ignore
            if (themeStyle[variable]) root.style.setProperty(`--${variable}`, themeStyle[variable]);
        });

        const faviconElement = getFaviconElement() as HTMLLinkElement;

        faviconElement.href = faviconUrl;

        setTheme(themeStyle as Theme);
    }

    return theme ?? (themeConfig['default'].themeStyle as Theme);
};

export default useThemeConfiguration;
