/* eslint-disable max-lines */

export const THE_CHAIR_YOGA_WOMAN_DEFAULT_FLOW = {
    branch_name: 'general',
    flow_name: 'yoga_go__general__Women_chair_v2__Control',
    start_page: {
        id: 'start_age_full2',
        name: 'start_age_full2_chair',
        config: {
            ScreenTitle: 'chair',
            Screen1Subtitle: 'default',
            Screen2Subtitle: 'default',
            femaleModelImage1: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_female_35.png',
            femaleModelImage2: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_female_45.png',
            femaleModelImage3: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_female_55.png',
            femaleModelImage4: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_female_65.png',
            femaleAgeRange1: '35-44',
            femaleAgeRange2: '45-54',
            femaleAgeRange3: '55-64',
            femaleAgeRange4: '65+',
            maleModelImage1: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_male_35.png',
            maleModelImage2: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_male_45.png',
            maleModelImage3: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_male_55.png',
            maleModelImage4: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_male_65.png',
            maleAgeRange1: '35-44',
            maleAgeRange2: '45-54',
            maleAgeRange3: '55-64',
            maleAgeRange4: '65+',
        },
    },
    onboarding_flow: [
        {
            id: 'ob_social_proof_full',
            name: 'ob_social_proof_full_old',
            config: {
                ScreenTitle: 'default',
                ScreenSubtitle: 'default',
                femaleModelImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/img-social-proof_female.png',
                maleModelImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/img-social-proof_default.png',
            },
        },
        { id: 'ob_goals', name: 'ob_goals', config: {} },
        {
            id: 'ob_familiarity_full',
            name: 'ob_familiarity_full_chair',
            config: { ScreenTitle: 'chair' },
        },
        { id: 'ob_body_type', name: 'ob_body_type', config: {} },
        { id: 'ob_body_type_target', name: 'ob_body_type_target', config: {} },
        {
            id: 'ob_target_zones_full',
            name: 'ob_target_zones_full_old',
            config: {
                femaleModelImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/zones-female2.png',
                maleModelImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/zones-male.png',
            },
        },
        { id: 'ob_body_image', name: 'ob_body_image', config: {} },
        { id: 'ob_activity_level', name: 'ob_activity_level', config: {} },
        { id: 'ob_walking', name: 'ob_walking', config: {} },
        { id: 'ob_pushups_squats', name: 'ob_pushups_squats', config: {} },
        { id: 'ob_yoga_level', name: 'ob_yoga_level', config: {} },
        { id: 'ob_bending_knees', name: 'ob_bending_knees', config: {} },
        { id: 'ob_tags_a', name: 'ob_tags_a', config: {} },
        { id: 'ob_height', name: 'ob_height', config: {} },
        { id: 'ob_weight', name: 'ob_weight', config: {} },
        { id: 'ob_weight_target', name: 'ob_weight_target', config: {} },
        { id: 'ob_age', name: 'ob_age', config: {} },
        { id: 'ob_occasion', name: 'ob_occasion', config: {} },
        { id: 'ob_occasion_result', name: 'ob_occasion_result', config: {} },
        { id: 'creating', name: 'creating', config: {} },
        { id: 'email_account_a', name: 'email_account_a', config: {} },
        {
            id: 'email_a_full',
            name: 'email_a_full_chair',
            config: { ScreenTitle: 'chair' },
        },
        {
            id: 'email_consent_full',
            name: 'email_consent_full_chair',
            config: { ScreenTitle: 'chair' },
        },
        {
            id: 'result_full',
            name: 'result_full_chair',
            config: { ScreenTitle: 'chair' },
        },
    ],
    payment_flow: [
        {
            id: 'payment_g11_golf14',
            name: 'payment_g11_golf14_chair_meta',
            parent_id: null,
            is_paid: null,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '7a83d865-4be2-41e1-92d6-072145e50d9e',
                    name: 'One-week intro (6.93) + One-month (38.95)',
                    trial: 7,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 254,
                    price_per_state: 99,
                    start_price: 1777,
                    price: 693,
                    sign: '$',
                    code: 'USD',
                    ltv: 4042,
                },
                {
                    payment_type: 'subscription',
                    id: 'd8939b06-6648-46c7-86fe-0c5df60ed569',
                    name: 'One-month intro (15.19) + One-month (38.95)',
                    trial: 31,
                    period: 31,
                    product_code: 'main',
                    is_preselected: true,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 126,
                    price_per_state: 49,
                    start_price: 3895.0000000000005,
                    price: 1519,
                    sign: '$',
                    code: 'USD',
                    ltv: 5169,
                },
                {
                    payment_type: 'subscription',
                    id: '6e0bbd02-ee0b-4c93-a86f-7181f6ec2f51',
                    name: 'Three-month intro (25.99) + Three-month (66.65)',
                    trial: 90,
                    period: 90,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 74,
                    price_per_state: 29,
                    start_price: 6665.000000000001,
                    price: 2599,
                    sign: '$',
                    code: 'USD',
                    ltv: 6505,
                },
                {
                    payment_type: 'subscription',
                    id: '743ec4d9-802f-4326-9907-d00a5d43ea1b',
                    name: 'One-week intro (17.77) + One-month (38.95)',
                    trial: 7,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 254,
                    start_price: 1777,
                    price: 1777,
                    sign: '$',
                    code: 'USD',
                    ltv: 4625,
                },
                {
                    payment_type: 'subscription',
                    id: '23e69cc7-d368-47b0-ad0b-42bf3c65735e',
                    name: 'One-month intro (38.95) + One-month (38.95)',
                    trial: 31,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 126,
                    start_price: 3895.0000000000005,
                    price: 3895,
                    sign: '$',
                    code: 'USD',
                    ltv: 6632,
                },
                {
                    payment_type: 'subscription',
                    id: 'a51e5fc3-aa3b-43eb-91e3-3b9c559d4666',
                    name: 'Three-month intro (66.65) + Three-month (66.65)',
                    trial: 90,
                    period: 90,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 74,
                    start_price: 6665.000000000001,
                    price: 6665,
                    sign: '$',
                    code: 'USD',
                    ltv: 9570,
                },
            ],
            config: {
                blocks: [
                    {
                        block_type: 'before_after_block',
                        visibility: 'on',
                        before_after_block: {
                            title: 'nowgoal',
                            body_fat: 'on',
                            level: 'yoga',
                            female_img_before:
                                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/female_fefore_payment.png',
                            female_img_after:
                                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/female_after_payment.png',
                            male_img_before:
                                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/male_before_payment_3d.png',
                            male_img_after:
                                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/male_after_payment_3d.png',
                        },
                    },
                    { block_type: 'title_block', visibility: 'on', title: 'chair' },
                    {
                        block_type: 'current_state_block',
                        visibility: 'on',
                        text1: 'target',
                        text2: 'yoga',
                    },
                    {
                        block_type: 'timer_badge_block',
                        visibility: 'on',
                        title: 'this_offer',
                    },
                    {
                        block_type: 'products_block',
                        visibility: 'on',
                        products_block: {
                            full_price: 'on',
                            free_guides_block: 'off',
                            social_proof_block: 'off',
                            trial_label: 'seven_day_trial',
                        },
                    },
                    { block_type: 'cta_title_block', visibility: 'on', title: '30day' },
                    {
                        block_type: 'disclaimer_block',
                        visibility: 'on',
                        title: 'new_text',
                    },
                    {
                        block_type: 'what_you_get_block',
                        visibility: 'on',
                        text1: 'chair',
                    },
                    {
                        block_type: 'calendar_image_block',
                        visibility: 'on',
                        before_after_block: {
                            female_img_before:
                                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/calendar_image_female.png',
                            male_img_before:
                                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/calendar_image_female.png',
                        },
                    },
                    { block_type: 'reviews_block', visibility: 'on' },
                    {
                        block_type: 'real_user_before_and_after_block',
                        visibility: 'on',
                        before_after_block: {
                            male_img_before:
                                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/Img.png',
                            female_img_before:
                                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/Img.png',
                        },
                    },
                    { block_type: 'title_block', visibility: 'on', title: 'chair' },
                    {
                        block_type: 'current_state_block',
                        visibility: 'on',
                        text1: 'target',
                        text2: 'yoga',
                    },
                    {
                        block_type: 'timer_badge_block',
                        visibility: 'on',
                        title: 'this_offer',
                    },
                    {
                        block_type: 'products_block',
                        visibility: 'on',
                        products_block: {
                            full_price: 'on',
                            free_guides_block: 'off',
                            social_proof_block: 'off',
                            trial_label: 'seven_day_trial',
                        },
                    },
                    { block_type: 'cta_title_block', visibility: 'on', title: '30day' },
                    {
                        block_type: 'disclaimer_block',
                        visibility: 'on',
                        title: 'new_text',
                    },
                ],
                checkout: {
                    checkoutPayPal: 'on',
                    checkoutGooglePay: 'off',
                    checkoutDisclaimer: 'off',
                    checkoutFullScreen: 'off',
                    ratings: 'off',
                    discount: 'off',
                    mainTitle: 'dont_miss',
                    subtitle: 'default',
                    secondTitle: 'off',
                    femaleImage: 'off',
                    maleImage: 'off',
                    label: '10_min',
                },
            },
        },
    ],
    expire: 1720848014986,
};
