/* eslint-disable max-lines */

export const SOMATIC_YOGA_GO_DEFAULT_FLOW = {
    branch_name: 'general',
    flow_name: 'yoga_go__general__women_somatic__Control',
    start_page: {
        id: 'start_age_full2',
        name: 'start_age_full2_somatic_2d_18_latam',
        config: {
            ScreenTitle: 'somatic',
            Screen1Subtitle: 'somatic',
            Screen2Subtitle: 'default',
            femaleAgeRange1: '18-25',
            femaleAgeRange2: '26-35',
            femaleAgeRange3: '36-45',
            femaleAgeRange4: '46+',
            maleAgeRange1: '18-25',
            maleAgeRange2: '26-35',
            maleAgeRange3: '36-45',
            maleAgeRange4: '46+',
            maleModelImage1: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/start-m_18-25.png',
            maleModelImage2: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/start-m_26-35.png',
            maleModelImage3: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/start-m_36-45.png',
            maleModelImage4: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/start-m_46.png',
            femaleModelImage1:
                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/ButtonWImage%20(1).png',
            femaleModelImage2:
                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/ButtonWImage%20(2).png',
            femaleModelImage3:
                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/ButtonWImage%20(3).png',
            femaleModelImage4:
                'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/ButtonWImage%20(4).png',
        },
    },
    onboarding_flow: [
        { id: 'ob_social_proof', name: 'ob_social_proof', config: {} },
        {
            id: 'ob_general2',
            name: 'ob_general2_goals_somatic_loc',
            config: {
                screenTitle: 'what_goal',
                screenSubtitle: 'off',
                option1: 'release',
                option2: 'weight_loss',
                option3: 'stress_anxiety',
                option4: 'pain',
                option5: 'flexibility',
            },
        },
        { id: 'ob_current_emotions', name: 'ob_current_emotions', config: {} },
        {
            id: 'ob_general1',
            name: 'ob_general1_stress',
            config: {
                screenTitle: 'stress',
                screenSubtitle: 'off',
                option1: 'never',
                option2: 'rarely',
                option3: 'sometimes',
                option4: 'pretty_often',
                option5: 'always',
            },
        },
        { id: 'ob_stress_look', name: 'ob_stress_look', config: {} },
        { id: 'ob_positive', name: 'ob_positive', config: {} },
        {
            id: 'ob_general4',
            name: 'ob_general4_brain_fog_loc',
            config: {
                screenTitle: 'brain_fog',
                screenSubtitle: 'brain_fog',
                option1: 'almost_always',
                option2: 'quite_often',
                option3: 'rarely',
                option4: 'never',
                option5: 'off',
            },
        },
        { id: 'ob_unfortunately_stress', name: 'ob_unfortunately_stress', config: {} },
        { id: 'ob_stress_time', name: 'ob_stress_time', config: {} },
        {
            id: 'ob_feedback',
            name: 'ob_feedback_stress_loc',
            config: {
                screenTitle: 'stress_burden',
                screenText: 'life_stressful',
                femaleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_relax_female.png',
                maleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_relax_female.png',
                femaleVideo: 'no',
                maleVideo: 'no',
                screenColor: '#F3F3F8',
            },
        },
        {
            id: 'ob_current_body_type_a',
            name: 'ob_current_body_type_a_v',
            config: {
                femaleOptionImage1:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_CF_regular-violet.png',
                femaleOptionImage2:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_CF_flabby-violet.png',
                femaleOptionImage3:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_CF_extra-violet.png',
                femaleOptionImage4:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_CF_over-violet.png',
                femaleOptionImage5:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/5_CF_obese-violet.png',
                maleOptionImage1:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_CM_Skinny.png',
                maleOptionImage2:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_CM_flabby.png',
                maleOptionImage3:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_CM_Extra.png',
                maleOptionImage4: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_CM_over.png',
                maleOptionImage5:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/5_CM_obese.png',
                ScreenTitle: 'bodytype',
            },
        },
        {
            id: 'ob_target_body_type_a',
            name: 'ob_target_body_type_a_v',
            config: {
                femaleOptionImage1:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_TF_regular-violet.png',
                femaleOptionImage2:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_TF_fit-violet.png',
                femaleOptionImage3:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_TF_flat-violet.png',
                femaleOptionImage4:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_TF_athletic-violet.png',
                femaleOptionImage5:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/5_TF_curvy-violet.png',
                maleOptionImage1: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_TM_Cut.png',
                maleOptionImage2:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_TM_Shredded.png',
                maleOptionImage3: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_TM_bulk.png',
                maleOptionImage4:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_TM_Extra_Bulk.png',
                maleOptionImage5:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/5_TM_Muscle.png',
                ScreenTitle: 'targetbodytype',
            },
        },
        { id: 'ob_target_zones_c', name: 'ob_target_zones_c_prob', config: { ScreenTitle: 'problemareas' } },
        { id: 'ob_body_image', name: 'ob_body_image', config: {} },
        {
            id: 'ob_feedback_a',
            name: 'ob_feedback_a_weightloss_loc',
            config: {
                screenTitle: 'weight_loss',
                screenText: 'weight_loss',
                femaleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_weight_loss.png',
                maleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG%28w%29/monetisation/feedback_weight_loss.png',
                femaleVideo: 'no',
                maleVideo: 'no',
                screenColor: '#F3F3F8',
            },
        },
        {
            id: 'ob_general5',
            name: 'ob_general5_tense_upd_loc',
            config: {
                screenTitle: 'tension',
                screenSubtitle: 'tension',
                option1: 'yes',
                option2: 'no',
                option3: 'off',
                option4: 'off',
                option5: 'off',
            },
        },
        {
            id: 'ob_general3',
            name: 'ob_general3_pain_loc',
            config: {
                screenTitle: 'where_pain',
                screenSubtitle: 'off',
                option1: 'hips_pelvis',
                option2: 'lower_back',
                option3: 'neck',
                option4: 'head',
                option5: 'other',
            },
        },
        { id: 'ob_sleep_problems', name: 'ob_sleep_problems', config: {} },
        {
            id: 'ob_feedback_b',
            name: 'ob_feedback_b_tension_loc',
            config: {
                screenTitle: 'relax',
                screenText: 'tension',
                femaleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/feedback_grey_female2.png',
                maleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/feedback_grey_female2.png',
                femaleVideo: 'no',
                maleVideo: 'no',
                screenColor: '#F3F3F8',
            },
        },
        { id: 'ob_activity_level', name: 'ob_activity_level', config: {} },
        { id: 'ob_walking', name: 'ob_walking', config: {} },
        {
            id: 'ob_familiarity_full',
            name: 'ob_familiarity_full_somatic_loc',
            config: { ScreenTitle: 'somatic' },
        },
        { id: 'ob_yoga_level', name: 'ob_yoga_level', config: {} },
        { id: 'ob_bending_knees', name: 'ob_bending_knees', config: {} },
        {
            id: 'ob_general',
            name: 'ob_general_motivation',
            config: {
                screenTitle: 'default',
                screenSubtitle: 'default',
                option1: 'default',
                option2: 'default',
                option3: 'default',
                option4: 'default',
                option5: 'default',
            },
        },
        { id: 'ob_selftime', name: 'ob_selftime', config: {} },
        { id: 'ob_height', name: 'ob_height', config: {} },
        { id: 'ob_weight', name: 'ob_weight', config: {} },
        { id: 'ob_weight_target', name: 'ob_weight_target', config: {} },
        { id: 'ob_age', name: 'ob_age', config: {} },
        { id: 'feedback_summary_fitlevel', name: 'feedback_summary_fitlevel', config: {} },
        { id: 'ob_occasion', name: 'ob_occasion', config: {} },
        { id: 'ob_occasion_result', name: 'ob_occasion_result', config: {} },
        { id: 'creating', name: 'creating', config: {} },
        {
            id: 'email_a_full',
            name: 'email_a_full_somatic_loc',
            config: { ScreenTitle: 'somatic' },
        },
        {
            id: 'email_consent_full',
            name: 'email_consent_full_somatic_loc',
            config: { ScreenTitle: 'somatic' },
        },
        {
            id: 'result_full',
            name: 'result_full_somatic_loc',
            config: { ScreenTitle: 'somatic' },
        },
    ],
    payment_flow: [
        {
            id: 'payment_g7_golf14',
            name: 'payment_g7_golf14_smtc_ltm_newprices_ppoff',
            parent_id: null,
            is_paid: null,
            skip_checkout: true,
            products: [
                {
                    payment_type: 'subscription',
                    id: '7800aa10-c1ff-4f12-93c2-9e731d71bfdd',
                    name: 'One week intro + One month + Payment geo_exp_lower',
                    trial: 7,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 122,
                    price_per_state: 79,
                    start_price: 853,
                    price: 554,
                    sign: 'CLP$',
                    code: 'CLP',
                    ltv: 4069,
                },
                {
                    payment_type: 'subscription',
                    id: '02f5680a-52f2-44d7-bf17-7eecfd0550b5',
                    name: 'One month intro + One month + Payment geo_exp_lower',
                    trial: 31,
                    period: 31,
                    product_code: 'main',
                    is_preselected: true,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 113,
                    price_per_state: 34,
                    start_price: 3494,
                    price: 1048,
                    sign: '$',
                    code: 'MXN',
                    ltv: 4293,
                },
                {
                    payment_type: 'subscription',
                    id: '272a8346-8fc9-427d-99c4-7a9e4499b5fc',
                    name: 'Three month intro + Three month + Payment geo_exp_lower',
                    trial: 90,
                    period: 90,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 64,
                    price_per_state: 19,
                    start_price: 5804,
                    price: 1741,
                    sign: '₱',
                    code: 'PHP',
                    ltv: 4360,
                },
                {
                    payment_type: 'subscription',
                    id: '33b1931a-c6d7-4e93-a596-1568949d756f',
                    name: 'One week intro + One month + Timer geo_exp_lower',
                    trial: 7,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 122,
                    start_price: 853,
                    price: 853,
                    sign: 'COL$',
                    code: 'COP',
                    ltv: 4279,
                },
                {
                    payment_type: 'subscription',
                    id: 'c9e43924-5dbd-4428-977d-9367e001c1df',
                    name: 'One month intro + One month + Timer geo_exp_lower',
                    trial: 31,
                    period: 31,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 113,
                    start_price: 3494,
                    price: 3494,
                    sign: '$',
                    code: 'MXN',
                    ltv: 5765,
                },
                {
                    payment_type: 'subscription',
                    id: '3568bb45-80b7-4fd3-852b-934fde10a901',
                    name: 'Three month intro + Three month + Timer geo_exp_lower',
                    trial: 90,
                    period: 90,
                    product_code: 'main',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 64,
                    start_price: 5804,
                    price: 5804,
                    sign: '$',
                    code: 'USD',
                    ltv: 7213,
                },
            ],
            config: {
                checkout: {
                    checkoutPayPal: 'off',
                    checkoutGooglePay: 'on',
                    checkoutFullScreen: 'off',
                    ratings: 'off',
                    secondTitle: 'off',
                    checkoutDisclaimer: 'off',
                    discount: 'off',
                    mainTitle: 'default',
                    subtitle: 'default',
                    label: 'off',
                    maleImage: 'off',
                    femaleImage: 'off',
                },
                femaleModelImageBefore:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/before-f.png',
                FullPrices: 'on',
                StatBadgeLevel: 'default',
                ScreenTitle: 'somatic',
                femaleModelImageAfter:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/after-f.png',
                maleModelImageBefore:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/fat.png',
                maleModelImageAfter:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/diversity/fit.png',
            },
        },
    ],
    expire: 1720863313107,
};
