import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { setGender } from 'redux/onboadring/actions';

import { THE_CHAIR_YOGA_MAN } from 'constants/mirrors/mirrorsHosts';

export function useMirrorsGenderSet() {
    const dispatch = useDispatch();

    useEffect(() => {
        const host = window.location.host;

        if (THE_CHAIR_YOGA_MAN.includes(host)) {
            dispatch(setGender('male'));
        }
    }, []);
}
