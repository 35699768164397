import { Init } from 'testania';

import config from 'config';

import { DEFAULT_FLOW_PROJECT_NAME, DEFAULT_EXPIRE_CONFIG_TIME } from 'constants/defaultFlows';

import { getMirrorConfigBy } from 'helpers/mirrors/mirrorFlowHelpers';
import { getVariantBranchName } from './helpers';

const { defaultFlow } = getMirrorConfigBy();

export const TESTANIA_INIT_CONFIG: Init = {
    project: DEFAULT_FLOW_PROJECT_NAME,
    testName: 'default',
    branchName: 'default',
    variantName: getVariantBranchName(),
    defaultBranches: ['default'],
    defaultFlow,
    testaniaUrl: config.TESTANIA_HOST,
    testaniaApiKey: config.TESTANIA_X_API_KEY,
    expireConfigTime: DEFAULT_EXPIRE_CONFIG_TIME,
    runOnlyLocalConfig: true,
};
